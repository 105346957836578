import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import logoImg from '../images/evolinq-blue-logo.svg';
import { LanguageContext } from '../context/LanguageContext';

const Slogan = () => {
    const { language } = useContext(LanguageContext);
    const isEnglish = language === 'en';

    return (
        <>
            <div className="hero min-h-screen pt-24 lg:pt-40" id="slogan">
                <div className="m-auto overflow-hidden mx-4 mt-6 lg:mt-5 p-2 md:p-12 h-full flex flex-col lg:flex-row items-center lg:items-center" data-aos="zoom-in">
                    <div className="w-full flex justify-center lg:w-2/3 order-1 lg:order-2 lg:ml-20 mb-12" data-aos="fade-up" data-aos-delay="700">
                        <img alt="card img" className="rounded-t duration-1000 w-2/3 lg:w-3/4" src={logoImg} />
                    </div>
                    <div id='slogan' className="flex flex-col justify-center text-center lg:text-left lg:w-2/3 order-2 lg:order-1 py-16 lg:pl-8">
                        <h1 className="mb-5 md:text-7xl text-4xl font-extrabold">
                            {isEnglish ? (
                                <>
                                    AI-Driven<br />Supply Chain <br />Management.
                                </>
                            ) : (
                                <>
                                    ניהול שרשרת<br />אספקה בעזרת<br />בינה מלאכותית.
                                </>
                            )}
                        </h1>
                        <div className="md:text-2xl text-xl font-semibold tracking-tight mb-5" id="slogan2">
                            {isEnglish ? (
                                <>
                                    Automating your entire procurement processes<br />
                                    with all of your suppliers.<br />
                                    Zero-Integrations required.<br />
                                    Nothing is required on the suppliers side.
                                </>
                            ) : (
                                <>
                                    אוטומציה של כל תהליכי הרכש.<br />
                                    עם כל הספקים שלכם.<br />
                                    אין צורך באינטגרציות.<br />
                                    אין צורך בשום דבר מצד הספקים.
                                </>
                            )}
                        </div>
                        <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/demo" className="text-white bg-[#166AEA] hover:bg-[#145BCF] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                {isEnglish ? 'Book a Demo' : 'קבעו הדגמה'}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Slogan;
