import React, { useState, useContext } from "react";
import NavBar from "../components/Navbar/NavBar";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../context/LanguageContext';

const DemoProduct = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === 'en';

  useDocTitle(isEnglish ? "Evolinq | Request a Demo" : "אבולינק | בקש הדגמה");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get('https://submit-form.com/zZBXqu2Bx', {
        params: {
          name,
          email,
          phone,
          message,
        },
      });
      swal(isEnglish ? "Success" : "הצלחה", isEnglish ? "We've got your message" : "קיבלנו את ההודעה שלך", "success");
      clearInput();
    } catch (error) {
      console.error("There was an error!", error);
      swal(isEnglish ? "Error" : "שגיאה", isEnglish ? "There was an error sending your message. Please contact us at sales@evolinq.io" : "הייתה שגיאה בשליחת ההודעה שלך. אנא פנה אלינו ב-sales@evolinq.io", "error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Evolinq | Request a Demo</title>
        <meta name="description" content={isEnglish ? "Request a free demo of Evolinq's AI-driven supply chain management platform and see how our solutions can optimize your factory's procurement process." : "בקשו הדגמה חינמית של הפלטפורמה לניהול שרשרת האספקה המונעת בבינה מלאכותית של אבולינק, וראו כיצד הפתרונות שלנו יכולים לייעל את תהליך הרכש במפעל שלכם."} />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Demo, Management, Free Demo" />
        <link rel="canonical" href="https://www.evolinq.io/demo" />
        <meta property="og:title" content="Request a Demo - Evolinq" />
        <meta property="og:description" content={isEnglish ? "Discover how Evolinq's AI-driven platform can transform your supply chain management. Request a free demo today!" : "גלו כיצד הפלטפורמה המונעת בבינה מלאכותית של אבולינק יכולה לשנות את ניהול שרשרת האספקה שלכם. בקשו הדגמה חינמית עוד היום!"} />
        <meta property="og:url" content="https://www.evolinq.io/demo" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image-demo.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div id="demo" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <form id="DemoProductForm" onSubmit={handleSubmit} autoComplete="on">
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mx-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left custom-blue uppercase text-4xl">
                  {isEnglish ? 'Request a free demo' : 'קבעו הדגמה חינמית'}
                </h1>
              </div>
              {errors && <p className="text-red-500 text-sm">{errors.products}</p>}
              <div className="grid grid-cols-1 gap-5 md:grid-cols-1 mt-5">
                <div className="w-full md:w-1/2">
                  <input
                    name="name"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder={isEnglish ? "Full Name*" : "שם מלא*"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyUp={clearErrors}
                    required
                    autoComplete="name"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                </div>
                <div className="w-full md:w-1/2">
                  <input
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email"
                    placeholder={isEnglish ? "Email*" : "אימייל*"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={clearErrors}
                    required
                    autoComplete="email"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="w-full md:w-1/2">
                  <input
                    name="phone"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder={isEnglish ? "Phone" : "טלפון"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyUp={clearErrors}
                    autoComplete="tel"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                </div>
              </div>
              <div className="my-4">
                <textarea
                  name="message"
                  placeholder={isEnglish ? "Message" : "הודעה"}
                  className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                ></textarea>
                {errors && <p className="text-red-500 text-sm">{errors.message}</p>}
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide custom-bg-color hover:custom-bg-color text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  {isEnglish ? 'Send Message' : 'שלח הודעה'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DemoProduct;
