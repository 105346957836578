import React, { useContext } from 'react';
import NavBar from '../components/Navbar/NavBar';
import { useDocTitle } from '../components/CustomHook';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../context/LanguageContext';

const Careers = () => {
  const { language } = useContext(LanguageContext);

  const isEnglish = language === 'en';

  useDocTitle(isEnglish ? "Evolinq | Careers" : "אבולינק | קריירה");

  return (
    <>
      <Helmet>
        <title>{isEnglish ? "Evolinq | Careers" : "אבולינק | קריירה"}</title>
        <meta 
          name="description" 
          content={isEnglish 
            ? "Join the Evolinq team and help us revolutionize supply chain management with AI."
            : "הצטרפו לצוות של אבולינק וקחו חלק במהפכה של ניהול שרשרת האספקה באמצעות AI."} 
        />
        <meta 
          name="keywords" 
          content={isEnglish 
            ? "Evolinq Careers, AI Jobs, Supply Chain Jobs"
            : "אבולינק קריירה, משרות בינה מלאכותית, משרות ניהול שרשרת אספקה"} 
        />
        <link rel="canonical" href="https://www.evolinq.io/careers" />
      </Helmet>
      <NavBar />
      <div 
        id="careers" 
        className="py-12 bg-cover bg-center min-h-screen"
      >
        <section data-aos="zoom-in-down">
          <div style={{backgroundColor: "black", backgroundImage: "linear-gradient(black , #1565C0)", paddingTop: "70px", paddingBottom: "70px", marginTop: "50px", marginBottom: "50px"}}>
          <div className="my-4 py-4">
            <h1 className="my-2 text-center text-6xl text-white font-bold">
              {isEnglish ? "Careers" : "קריירה"}
            </h1>
            <h3 className="text-center text-3xl text-white mb-10 mt-10">
              {isEnglish 
                ? "Join the Future of Supply Chain Innovation"
                : "הצטרפו לעתיד החדשנות בניהול שרשרת האספקה"}
            </h3>
          </div>
          </div>
          <h4 className="text-center text-lg custom-blue mb-12 pl-4 pr-4">
            {isEnglish 
              ? "Join the Evolinq Family and Make a Lasting Impact on the Supply Chain World. Be part of an innovative team, working with cutting-edge technologies to drive transformative change and excellence."
              : "הצטרפו למשפחת אבולינק והשפיעו על עולם שרשרת האספקה. היו חלק מצוות חדשני שעובד עם טכנולוגיות מתקדמות כדי להוביל שינוי משמעותי."}
          </h4>
          <div data-aos="fade-down" data-aos-delay="600" className="mx-auto max-w-6xl">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5">
              
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl p-5 group">
                <h3 className="font-semibold text-2xl text-center custom-blue mb-4 group-hover:custom-blue">
                  {isEnglish ? "Customer Success Specialist" : "מומחה שירות לקוחות"}
                </h3>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>{isEnglish ? "Fluent in Hebrew and English" : "שליטה בעברית ובאנגלית"}</li>
                  <li>{isEnglish ? "Technologically adept with a quick learning ability" : "מיומנות טכנולוגית עם יכולת למידה מהירה"}</li>
                  <li>{isEnglish ? "Strong analytical and problem-solving skills" : "כישורים אנליטיים ויכולת פתרון בעיות"}</li>
                  <li>{isEnglish ? "Excellent interpersonal and communication skills" : "תקשורת בין אישית מצויינת"}</li>
                  <li>{isEnglish ? "Proficient in delivering training sessions" : "בקיאות בהעברת הדרכות"}</li>
                  <li>{isEnglish ? "Available during daytime hours" : "זמינות בשעות היום"}</li>
                  <li>{isEnglish ? "Patient and customer-oriented" : "סבלנות ומיקוד בלקוח"}</li>
                </ul>
                <h4 className="font-semibold text-xl custom-blue mt-4 group-hover:custom-blue">
                  {isEnglish ? "Preferred Qualifications:" : "בונוס:"}
                </h4>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>{isEnglish ? "Experience in procurement or supply chain management" : "ניסיון בניהול רכש או ניהול שרשרת אספקה"}</li>
                  <li>{isEnglish ? "Experience in presenting Release Notes to customers" : "ניסיון בהצגת מערכות ללקוחות"}</li>
                </ul>
                <div className="text-white custom-bg-color text-center hover:custom-bg-color inline-flex w-auto px-6 py-3 shadow-xl rounded-xl mx-auto mt-5"
                  onClick={() => window.open('mailto:careers@evolinq.io?subject=Evolinq Career Application: Customer Success Specialist')}
                  style={{ cursor: 'pointer' }}
                >
                  {isEnglish ? "Apply" : "הגש מועמדות"}
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl p-5 group">
                <h3 className="font-semibold text-2xl text-center custom-blue mb-4 group-hover:custom-blue">
                  {isEnglish ? "Full Stack Senior Developer" : "מפתח Full Stack בכיר"}
                </h3>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>{isEnglish ? "Strong experience in full-stack development" : "ניסיון בפיתוח Full Stack"}</li>
                  <li>{isEnglish ? "Proficiency in Python and LLMs (Large Language Models)" : "שליטה בפייתון ובמודלימ שפה גדולים (LLMs)"}</li>
                  <li>{isEnglish ? "Extensive knowledge of React and TypeScript" : "ידע נרחב ב-React וב-TypeScript"}</li>
                  <li>{isEnglish ? "Experience with AWS cloud services" : "ניסיון עם שירותי הענן של AWS"}</li>
                  <li>{isEnglish ? "Proficiency in MongoDB for database management" : "שליטה ב-MongoDB לניהול מסדי נתונים"}</li>
                  <li>{isEnglish ? "Strong problem-solving skills and analytical thinking" : "כישורי פתרון בעיות וחשיבה אנליטית"}</li>
                  <li>{isEnglish ? "Excellent communication and teamwork abilities" : "כישורי תקשורת בין אישית ועבודת צוות"}</li>
                </ul>
                <h4 className="font-semibold text-xl custom-blue mt-4 group-hover:custom-blue">
                  {isEnglish ? "Preferred Qualifications:" : "בונוס:"}
                </h4>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>{isEnglish ? "Experience with the Retool platform" : "ניסיון עם פלטפורמת Retool"}</li>
                  <li>{isEnglish ? "Experience with prompt engineering" : "ניסיון בכתיבת פרומפטים למודלי שפה"}</li>
                </ul>
                <div className="text-white custom-bg-color text-center hover:custom-bg-color inline-flex w-auto px-6 py-3 shadow-xl rounded-xl mx-auto mt-5"
                  onClick={() => window.open('mailto:careers@evolinq.io?subject=Evolinq Career Application: Full Stack Senior Developer')}
                  style={{ cursor: 'pointer' }}
                >
                  {isEnglish ? "Apply" : "הגש מועמדות"}
                </div>
              </div>
            </div>
          </div>
        </section>
        <p className="text-center mt-12 text-gray-700">
          {isEnglish 
            ? "For further information feel free to reach us at" 
            : "לפרטים נוספים אנא צרו קשר בכתובת"}{' '}
          <a href="mailto:careers@evolinq.io" className="custom-blue hover:underline">
            careers@evolinq.io
          </a>.
        </p>
      </div>
    </>
  );
};

export default Careers;
