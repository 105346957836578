import React, { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { LanguageContext } from '../../context/LanguageContext';

const NavLinks = () => {
    const { language } = useContext(LanguageContext);
    const isEnglish = language === 'en';

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -120;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    return (
        <>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to={`/${language}/#about`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'About' : 'אודות'}
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to={`/${language}/#services`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'Platform' : 'פלטפורמה'}
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to={`/${language}/#why-evolinq`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'Why us?' : 'למה אנחנו?'}
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to={`/${language}/careers#careers`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'Careers' : 'קריירה'}
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to={`/${language}/blog#blog`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'Blog' : 'בלוג'}
            </HashLink>
            <HashLink
                className="text-white custom-bg-color inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                smooth
                to={`/${language}/demo#demo`}
                scroll={el => scrollWithOffset(el)}
            >
                {isEnglish ? 'Book a Demo' : 'קבעו הדגמה'}
            </HashLink>
        </>
    );
};

export default NavLinks;
